import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/Layout/Layout"
import Navbar from "../components/Navbar/index"
import Products from "../components/Products"
import { productData, productDataTwo } from "../components/Products/data"
import Cart from "../components/Cart"
import Footer from "../components/Footer"
import Sidebar from "../components/Sidebar"

const Warper = styled.div`
  background: #000;
`

function Menu() {
  const [isOpen, setIsOpen] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const toggleHandler = e => {
    setCartOpen(!cartOpen)
  }

  return (
    <Layout>
      <div>
        <Warper>
          <Navbar toggle={toggle} toggleHandler={toggleHandler} />
        </Warper>
        <Cart cartOpen={cartOpen} toggleHandler={toggleHandler} />
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Products heading="ENJOY THE TASTE" data={productData} />

        <Products heading="" data={productDataTwo} />
        <Footer />
      </div>
    </Layout>
  )
}

export default Menu
